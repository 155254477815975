/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    hash?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    conversationId?: string | null;
}

/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value: object): value is ChatMessage {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'url': json['url'] == null ? undefined : json['url'],
        'date': json['date'] == null ? undefined : json['date'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'message': json['message'],
        'conversationId': json['conversation_id'] == null ? undefined : json['conversation_id'],
    };
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'url': value['url'],
        'date': value['date'],
        'hash': value['hash'],
        'message': value['message'],
        'conversation_id': value['conversationId'],
    };
}

