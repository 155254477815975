import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Chat from './components/Chat';
import Terms from './components/Terms';

function App() {
    const { t } = useTranslation();

    let currentPage = window.location.pathname;
    if (currentPage.endsWith('/')) {
        currentPage = currentPage.slice(0, -1);
    }

    useEffect(() => {
        document.title = t('title');
    }, [t]);

    return <>{currentPage === '/terms' ? <Terms /> : <Chat />}</>;
}

export default App;
