import React from 'react';
import Icon from './Icon';

interface LinkProps {
    href: string;
    children: string;
    showIcon?: boolean;
}

export const Link: React.FC<LinkProps> = ({ href, children, showIcon = true }) => {
    return (
        <>
            <a
                href={href}
                target="_blank"
                rel="noreferrer noopener"
                className="mr-1 text-primary underline hover:text-status-info"
            >
                {children}
            </a>
            {showIcon && (
                <div className="fill-primary">
                    <Icon icon="hyperlink" />
                </div>
            )}
        </>
    );
};

export default Link;
