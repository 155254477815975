import React, { cloneElement, ReactElement } from 'react';
import Button from './Button';
import Icon from './Icon';

export interface ToggleButtonProps {
    value: any;
    children: React.ReactNode;
    checked?: boolean;
    tooltip?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ tooltip, checked = false, children, onClick }) => {
    const isIcon = (element: React.ReactNode): element is ReactElement => {
        return React.isValidElement(element) && element.type === Icon;
    };

    const modifiedChildren = React.Children.map(children, (child) =>
        isIcon(child) ? cloneElement(child, { fill: checked, animateFill: true }) : child,
    );

    return (
        <Button variant="tertiary" tooltip={tooltip} onClick={onClick}>
            {modifiedChildren}
        </Button>
    );
};

export default ToggleButton as React.FC<ToggleButtonProps>;
