import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Source } from '../api-client';
import { useApiRateReply } from '../hooks/useApi';
import { Button, Icon, Spinner, ToggleButton, ToggleButtonGroup } from '../shared/components';
import SourceList from './SourceList';

interface MessageListItemProps {
    variant: 'bot' | 'user';
    text?: string;
    isLoading: boolean;
    sources?: Source[];
    messageId?: string;
}

const MessageListItem = forwardRef<HTMLDivElement, MessageListItemProps>(
    ({ variant, text, sources = [], isLoading = false, messageId }, ref) => {
        const { t } = useTranslation();
        const { rateReply } = useApiRateReply();
        const [isRendered, setIsRendered] = useState<Boolean>(false);

        const handleRatingChange = async (rating: any): Promise<void> => {
            if (messageId) {
                await rateReply(rating, messageId);
            }
        };

        const handleCopy = (text: string = ''): void => {
            if (sources.length > 0) {
                text += '\n';
            }
            sources.forEach((source, index) => {
                text += `\n* [${index + 1}] ${source.title}`;
            });

            navigator.clipboard.writeText(text);
        };

        let classNameVariantContainer: string = '';
        let classNameVariantContent: string = '';
        let classNameTransition: string = '';
        let sender: string = '';

        if (variant === 'user') {
            classNameVariantContainer = 'items-end';
            classNameVariantContent = 'ml-auto bg-item-selected';
            sender = t('roleUser');
        } else {
            classNameVariantContainer = 'items-start';
            classNameVariantContent = 'mr-auto bg-item';
            sender = t('roleBot');
        }

        if (variant === 'user' || isLoading) {
            classNameTransition = `transform transition-all duration-300 ease-in-out ${isLoading ? 'delay-300' : 'delay-0'} ${isRendered ? 'translate-none opacity-100' : 'translate-y-4 opacity-0'}`;
        }

        useEffect(() => {
            setIsRendered(true);
        }, []);

        return (
            <div
                ref={ref}
                className={`${classNameVariantContainer} ${classNameTransition} mx-auto mb-10 inline-flex w-full max-w-screen-lg flex-col justify-start gap-1`}
            >
                <p className="text-sm text-primary">{sender}</p>
                <div
                    className={`${classNameVariantContent} inline-flex size-full  max-w-screen-md flex-col justify-start gap-2 rounded px-3 py-2`}
                >
                    <Spinner show={isLoading}></Spinner>

                    {text !== undefined && (
                        <Markdown
                            remarkPlugins={[remarkGfm]}
                            className="prose prose-sm max-w-none text-dark prose-li:marker:text-dark"
                        >
                            {text}
                        </Markdown>
                    )}

                    {!isLoading && variant === 'bot' && sources.length > 0 && (
                        <SourceList className="mt-2" sources={sources} />
                    )}
                </div>

                {variant === 'user' && (
                    <div className="inline-flex w-full max-w-screen-md items-start justify-end">
                        <div className="rounded bg-white drop-shadow">
                            <Button variant="tertiary" onClick={() => handleCopy(text)} tooltip={t('buttonCopy')}>
                                <Icon icon="copy" />
                            </Button>
                        </div>
                    </div>
                )}

                {!isLoading && variant === 'bot' && (
                    <div className="inline-flex w-full max-w-screen-md items-start justify-between gap-2">
                        <p className="text-sm text-primary">{t('hintAi')}</p>
                        <div className="inline-flex flex-row items-start justify-end rounded bg-white drop-shadow">
                            <Button variant="tertiary" onClick={() => handleCopy(text)} tooltip={t('buttonCopy')}>
                                <Icon icon="copy" />
                            </Button>
                            <ToggleButtonGroup onChange={handleRatingChange}>
                                <ToggleButton value={1} tooltip={t('buttonLikeReply')}>
                                    <Icon icon="thumbUp" />
                                </ToggleButton>
                                <ToggleButton value={-1} tooltip={t('buttonDislikeReply')}>
                                    <Icon icon="thumbDown" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                )}
            </div>
        );
    },
);

export default MessageListItem;
