import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Icon from './Icon';

interface AlertProps {
    variant: 'info' | 'success' | 'warning' | 'error';
    className?: string;
    children: string | null;
}

export const Alert: React.FC<AlertProps> = ({ variant, children = '', className }) => {
    const { t } = useTranslation();
    const [show, setShow] = React.useState<Boolean>(true);

    let classNameVariant: string = '';
    switch (variant) {
        case 'info':
            classNameVariant = 'bg-status-info text-white';
            break;
        case 'success':
            classNameVariant = 'bg-status-success text-white';
            break;
        case 'warning':
            classNameVariant = 'bg-status-warning text-dark';
            break;
        case 'error':
            classNameVariant = 'bg-status-error text-white fill-white';
            break;
    }

    const handleDismiss = () => {
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <div
            className={`${className} ${classNameVariant} inline-flex flex-row items-start justify-between gap-4 self-stretch rounded px-3 py-2 text-base `}
        >
            <span className="self-stretch align-middle">{children}</span>
            <Button variant="tertiary" size="small" tooltip={t('buttonClose')} onClick={handleDismiss}>
                <span className="fill-white">
                    <Icon icon="x" />
                </span>
            </Button>
        </div>
    );
};

export default Alert;
