import React from 'react';

interface ButtonProps {
    disabled?: boolean;
    variant?: 'primary' | 'secondary' | 'tertiary';
    size?: 'small' | 'medium';
    tooltip?: string;
    className?: string;
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button: React.FC<ButtonProps> = ({
    disabled = false,
    variant = 'primary',
    size = 'medium',
    tooltip,
    className,
    children,
    onClick,
}) => {
    let classNameVariant: string = '';
    let classNameVariantHover: string = '';
    let classNameVariantDisabled: string = '';
    let classNameSize: string = '';

    switch (variant) {
        case 'primary':
            classNameVariant = 'text-white fill-white border-primary bg-primary';
            classNameVariantHover = 'hover:bg-hover-primary hover:border-hover-primary';
            classNameVariantDisabled = 'disabled:bg-inactive disabled:border-inactive';
            break;
        case 'secondary':
            classNameVariant = 'bg-transparent border-primary text-primary fill-primary';
            classNameVariantHover =
                'hover:bg-hover-secondary hover:border-hover-secondary hover:text-white hover:fill-white';
            classNameVariantDisabled =
                'disabled:bg-transparent disabled:border-inactive disabled:text-inactive disabled:fill-inactive';
            break;
        case 'tertiary':
            classNameVariant = 'bg-transparent border-transparent text-primary fill-primary';
            classNameVariantHover = 'hover:bg-hover-tertiary hover:border-hover-tertiary';
            classNameVariantDisabled =
                'disabled:bg-transparent disabled:border-transparent disabled:text-inactive disabled:fill-inactive';
            break;
    }

    switch (size) {
        case 'small':
            classNameSize = 'h-5 py-0.5 px-0.5 text-sm';
            break;
        case 'medium':
            classNameSize = 'h-9 py-2 px-3 text-base';
            break;
    }

    const classNameBase = 'transition inline-flex items-center justify-center gap-2 rounded border';

    return (
        <button
            className={`${className} ${classNameBase} ${classNameVariant} ${classNameVariantHover} ${classNameVariantDisabled} ${classNameSize}`}
            disabled={disabled}
            aria-disabled={disabled}
            title={tooltip}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button as React.FC<ButtonProps>;
