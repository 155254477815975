import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Source } from '../api-client';
import { Button, Icon, Link } from '../shared/components';
import SourceListItem from './SourceListItem';

interface SourceListProps {
    sources: Source[];
    className?: string;
}

const SourceList: React.FC<SourceListProps> = ({ sources, className }) => {
    const { t } = useTranslation();
    const [activeSource, setactiveSource] = useState<Source | null>(null);
    const refSource = useRef<HTMLDivElement>(null);

    const handleActivate = (source: Source | null = null) => {
        if (source?.id === undefined || source?.id === activeSource?.id) {
            setactiveSource(null);
            return;
        }
        setactiveSource(source);
    };

    useEffect(() => {
        if (activeSource?.id !== undefined) {
            refSource.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [activeSource]);

    return (
        <div
            className={`${className} inline-flex flex-col items-start justify-start gap-3 self-stretch`}
            ref={refSource}
        >
            <div className="inline-flex flex-row flex-wrap items-center justify-start gap-2 self-stretch truncate text-sm text-dark">
                <span>{t('labelSources')}:</span>
                <ul className="inline-flex flex-row flex-wrap items-center justify-start gap-2">
                    {sources.map((source, index) => (
                        <SourceListItem
                            number={index + 1}
                            title={source.title}
                            onActivate={() => handleActivate(source)}
                            active={activeSource?.id === source.id}
                            key={index}
                        />
                    ))}
                </ul>
            </div>
            {activeSource?.id !== undefined && (
                <div className="self-stretch rounded bg-white px-3 py-2 drop-shadow">
                    <div className="inline-flex w-full flex-col items-end justify-start gap-2 border-l-4 border-item pl-2 text-base text-dark">
                        <div className="inline-flex flex-row items-center justify-start self-stretch">
                            <span className="inline-flex w-full flex-row items-center justify-start overflow-hidden">
                                {activeSource.url && <Link href={activeSource.url}>{t('linkOpenSource')}</Link>}
                            </span>
                            <Button variant="tertiary" tooltip={t('buttonClose')} onClick={() => handleActivate()}>
                                <Icon icon="x" />
                            </Button>
                        </div>

                        <Markdown
                            remarkPlugins={[remarkGfm]}
                            className="prose prose-sm max-w-none self-stretch text-dark"
                        >
                            {activeSource.content}
                        </Markdown>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SourceList;
