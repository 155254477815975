import React, { ReactNode, cloneElement, useState } from 'react';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';

interface ToggleButtonGroupProps {
    children: ReactNode;
    onChange?: (value: any) => void;
}

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({ children, onChange }) => {
    const [value, setValue] = useState<any>(null);

    const handleClick = (toggleValue: any) => {
        let newValue = toggleValue === value ? null : toggleValue;
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const modifiedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement<ToggleButtonProps>(child) && child.type === ToggleButton) {
            return cloneElement(child, {
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                    if (child.props.onClick) {
                        child.props.onClick(e);
                    }
                    handleClick(child.props.value);
                },
                checked: child.props.value === value,
            });
        }
        return child;
    });

    return <div className="inline-flex flex-row items-center justify-end">{modifiedChildren}</div>;
};

export default ToggleButtonGroup;

