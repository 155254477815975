import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
    de: {
        translation: {
            title: 'MIL Office KI-Supporter',
            hintAi: 'KI-gestützte Antwort. Ergebnisse kritisch prüfen.',
            hintPersonalData: 'Fügen Sie keine personenbezogenen oder klassifizierten Informationen ein.',
            buttonSubmit: 'Senden',
            buttonNewConversation: 'Neues Thema',
            buttonLikeReply: 'Die Antwort war hilfreich',
            buttonDislikeReply: 'Die Antwort war nicht hilfreich',
            buttonClose: 'Schliessen',
            buttonCopy: 'Inhalt der Nachricht in die Zwischenablage kopieren',
            placeholderQuestion: 'Stellen Sie Ihre Frage...',
            introduction: 'Ihr MIL Office KI-Supporter',
            roleUser: 'Sie',
            roleBot: 'Support Chat',
            labelSources: 'Quellen',
            linkOpenSource: 'zur Quelle',
            buttonSource: 'Quelle {{n}}',
            linkTerms: 'Nutzungsbedingungen',
            error: 'Bei der Anfrage ist ein Problem aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmals.',
        },
    },
    fr: {
        translation: {
            title: 'Support IA de MIL Office',
            hintAi: 'Réponse assistée par IA. Vérifiez les résultats avec soin.',
            // eslint-disable-next-line @typescript-eslint/quotes
            hintPersonalData: "Ne pas inclure d'informations personnelles ou classifiées.",
            buttonSubmit: 'Envoyer',
            buttonNewConversation: 'Nouveau sujet',
            buttonLikeReply: 'La réponse était utile',
            // eslint-disable-next-line @typescript-eslint/quotes
            buttonDislikeReply: "La réponse n'était pas utile",
            buttonClose: 'Fermer',
            buttonCopy: 'Copier le contenu du message dans le presse-papiers',
            placeholderQuestion: 'Posez votre question...',
            introduction: 'Votre support IA de MIL Office',
            roleUser: 'Vous',
            roleBot: 'Chat de support',
            labelSources: 'Sources',
            linkOpenSource: 'vers la source',
            buttonSource: 'Source {{n}}',
            // eslint-disable-next-line @typescript-eslint/quotes
            linkTerms: "Conditions d'utilisation",
            error: 'Un problème est survenu lors de la demande. Veuillez réessayer plus tard.',
        },
    },
    it: {
        translation: {
            title: 'Supporto IA di MIL Office',
            hintAi: 'Risposta assistita da IA. Controlla attentamente i risultati.',
            hintPersonalData: 'Non inserire informazioni personali o classificate.',
            buttonSubmit: 'Invia',
            buttonNewConversation: 'Nuovo argomento',
            buttonLikeReply: 'La risposta è stata utile',
            buttonDislikeReply: 'La risposta non è stata utile',
            buttonClose: 'Chiudi',
            buttonCopy: 'Copia il contenuto del messaggio negli appunti',
            placeholderQuestion: 'Fai la tua domanda...',
            introduction: 'Il tuo supporto IA di MIL Office',
            roleUser: 'Tu',
            roleBot: 'Chat di supporto',
            labelSources: 'Fonti',
            linkOpenSource: 'alla fonte',
            buttonSource: 'Fonte {{n}}',
            linkTerms: 'Termini di utilizzo',
            error: 'Si è verificato un problema con la richiesta. Si prega di riprovare più tardi.',
        },
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: resources,
        supportedLngs: ['de', 'fr', 'it'],
        fallbackLng: 'de',
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lang',
        },
    });
