import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Link } from '../shared/components';

interface QuestionProps {
    readOnly: boolean;
    onSubmit?: (question: string) => void;
}

const Question = forwardRef<HTMLTextAreaElement, QuestionProps>(({ readOnly, onSubmit }, ref) => {
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState('');

    const submit = () => {
        if (onSubmit) {
            onSubmit(value);
        }
        setValue('');
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            if (readOnly === false && value.trim()) {
                submit();
            }
            event.preventDefault();
        }
    };

    const classNameInput = 'col-start-1 col-end-2 row-start-1 row-end-2 text-base pl-3 py-2 pr-12 rounded border';
    const classNameInputMirror =
        'after:col-start-1 after:col-end-2 after:row-start-1 after:row-end-2 after:text-base after:pl-3 after:py-2 after:pr-12 after:rounded after:border';

    return (
        <div className="inline-flex grow flex-col items-center justify-start bg-white">
            <div className="inline-flex flex-col items-center justify-start gap-1 self-stretch">
                <div
                    className={`relative grid self-stretch after:invisible after:whitespace-pre-wrap after:content-[attr(data-value)] ${classNameInputMirror}`}
                    data-value={value + ' '}
                >
                    <textarea
                        ref={ref}
                        className={`resize-none overflow-hidden border-input text-primary focus:border-input-focused focus:outline-none ${classNameInput}`}
                        placeholder={t('placeholderQuestion')}
                        rows={1}
                        value={value}
                        onInput={handleInput}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="absolute inset-y-[9px] right-3 inline-flex flex-col items-end justify-end border-l border-input pl-3">
                        <Button
                            variant="tertiary"
                            size="small"
                            disabled={readOnly || value.trim() === ''}
                            tooltip={t('buttonSubmit')}
                            onClick={submit}
                        >
                            <Icon icon="paperPlane" />
                        </Button>
                    </div>
                </div>
                <span className="text-center text-sm">
                    <Link href={`/terms?lang=${i18n.language}`} showIcon={false}>
                        {t('hintPersonalData')}
                    </Link>
                </span>
            </div>
        </div>
    );
});

export default Question;
