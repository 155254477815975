import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ReactComponent as Logo } from '../shared/assets/logo.svg';

const content = {
    de: `
# Nutzungsbedingungen MIL Office KI-Supportchat

Diese Nutzungsbedingungen regeln den Zugriff auf und die Nutzung des **KI-Supportchats für die Software MIL Office** (nachfolgend **„Supportchat“** genannt) im Umfeld des Schweizer Militärs. Mit der Nutzung des Supportchats erklären Sie sich mit diesen Bedingungen einverstanden.

## 1. Geltungsbereich und Zweck

Der Supportchat dient der Unterstützung von autorisierten Benutzern der Software MIL Office durch Antworten auf häufige Fragen, Bereitstellung von Anleitungen und Unterstützung bei der Nutzung der Funktionen. Der Chat ist ausschließlich für militärische Zwecke und autorisierte Benutzer zugänglich.

## 2. Datensicherheit und Vertraulichkeit

Der Supportchat ist so konzipiert, dass die Vertraulichkeit der Kommunikation gewährleistet ist. Dennoch sollten keine klassifizierten oder peronenbezogenen Informationen in den Chat eingegeben werden. Jegliche Eingaben und Anfragen müssen sich auf allgemeine oder technische Fragen zur Nutzung der Software MIL Office beschränken. Für vertrauliche oder sicherheitsrelevante Anliegen sind offizielle Kommunikationswege zu nutzen.

## 3. Datenschutz

Die personenbezogenen Daten der Benutzer werden gemäß den geltenden Datenschutzrichtlinien der Schweizer Armee verarbeitet. Die Kommunikation im Supportchat wird zu Analysezwecken und zur Verbesserung der Servicequalität anonymisiert aufgezeichnet und ausgewertet. Zur Sicherstellung der Funktionalität des Supportchats und der Software MIL Office werden die Daten auch mit dem Lieferanten der Software und des Supportchats geteilt. Diese Weitergabe erfolgt ausschließlich zur Verbesserung des Services und unter Wahrung der Vertraulichkeit. Eine Weitergabe an sonstige Dritte, die nicht dem Schweizer Militär oder dem Lieferanten von MIL Office angehören, ist ausgeschlossen.

## 4. Haftungsausschluss

Der Supportchat basiert auf einer KI-Technologie und dient der Bereitstellung allgemeiner Informationen. Der Betreiber des Chats übernimmt keine Haftung für direkte oder indirekte Schäden, die aus der Nutzung des Chats entstehen könnten. Die gegebenen Antworten sind unverbindlich und müssen vor ihrer Anwendung von den Benutzern selbst auf Richtigkeit und Vollständigkeit überprüft werden.

## 5. Einschränkungen der Nutzung

Die Nutzer verpflichten sich, den Supportchat ausschließlich zur Unterstützung der eigenen Arbeit im Zusammenhang mit der Software MIL Office zu nutzen. Missbrauch des Chats, z. B. für private Zwecke, unangebrachte Inhalte oder sicherheitsrelevante Themen, ist untersagt.

## Impressum und Kontakt

Verteidigung, Bevölkerungsschutz und Sport VBS   
Kommando Ausbildung   
Papiermühlestrasse 14   
3003 Bern   
email: triagemiloffice.ausb@vtg.admin.ch   
    `,
    fr: `
# Conditions d'utilisation du Chat de Support KI pour MIL Office

Les présentes conditions d'utilisation régissent l'accès et l'utilisation du **Chat de Support KI pour le logiciel MIL Office** (ci-après dénommé **"Chat de Support"**) dans le cadre de l'Armée suisse. En utilisant ce Chat de Support, vous acceptez ces conditions.

## 1. Domaine d'application et objectif

Le Chat de Support est destiné à fournir une assistance aux utilisateurs autorisés du logiciel MIL Office en répondant aux questions fréquentes, en fournissant des guides et en offrant un soutien pour l'utilisation des fonctionnalités. Le chat est exclusivement accessible à des fins militaires et uniquement aux utilisateurs autorisés.

## 2. Sécurité des données et confidentialité

Le Chat de Support est conçu pour garantir la confidentialité des communications. Toutefois, il est déconseillé d'y saisir des informations classifiées ou des données personnelles. Toutes les demandes et saisies doivent être limitées aux questions générales ou techniques concernant l'utilisation du logiciel MIL Office. Pour les questions confidentielles ou liées à la sécurité, des canaux de communication officiels doivent être utilisés.

## 3. Protection des données

Les données personnelles des utilisateurs sont traitées conformément aux directives de protection des données en vigueur dans l'Armée suisse. La communication via le Chat de Support est enregistrée et analysée de manière anonymisée à des fins d'amélioration de la qualité du service. Afin de garantir le bon fonctionnement du Chat de Support et du logiciel MIL Office, les données peuvent être partagées avec le fournisseur du logiciel et du Chat de Support. Ce partage de données se fait exclusivement dans le but d'améliorer le service tout en préservant la confidentialité. La transmission à des tiers autres que l'Armée suisse ou le fournisseur de MIL Office est exclue.

## 4. Limitation de responsabilité

Le Chat de Support repose sur une technologie d'intelligence artificielle et est destiné à fournir des informations générales. L'exploitant du Chat décline toute responsabilité en cas de dommages directs ou indirects résultant de l'utilisation du Chat. Les réponses fournies sont indicatives et doivent être vérifiées par les utilisateurs quant à leur exactitude et leur exhaustivité avant d'être appliquées.

## 5. Restrictions d'utilisation

Les utilisateurs s'engagent à utiliser le Chat de Support uniquement pour soutenir leur travail lié à l'utilisation du logiciel MIL Office. Toute utilisation abusive du Chat, par exemple à des fins privées, pour des contenus inappropriés ou pour des sujets sensibles en matière de sécurité, est interdite.

## Mentions légales et contact

Département fédéral de la défense, de la protection de la population et des sports (DDPS)   
Commandement de l’Instruction   
Papiermühlestrasse 14  
3003 Berne  
email : triagemiloffice.ausb@vtg.admin.ch
    `,
    it: `
# Termini di utilizzo del Supporto Chat KI MIL Office

Questi termini di utilizzo regolano l'accesso e l'uso della **chat di supporto KI per il software MIL Office** (di seguito **"Supporto Chat"**) nel contesto delle Forze Armate Svizzere. L'utilizzo della chat di supporto implica l'accettazione di questi termini.

## 1. Ambito di applicazione e scopo

La chat di supporto è destinata ad assistere gli utenti autorizzati del software MIL Office, rispondendo a domande frequenti, fornendo istruzioni e supporto nell'uso delle funzioni. La chat è accessibile esclusivamente per scopi militari e solo agli utenti autorizzati.

## 2. Sicurezza dei dati e riservatezza

La chat di supporto è progettata per garantire la riservatezza della comunicazione. Tuttavia, non devono essere inserite informazioni classificate o personali. Tutti i messaggi e le richieste devono limitarsi a domande generali o tecniche sull'utilizzo del software MIL Office. Per questioni riservate o di sicurezza, devono essere utilizzate le vie ufficiali di comunicazione.

## 3. Protezione dei dati

I dati personali degli utenti sono trattati in conformità con le normative sulla protezione dei dati dell'Esercito Svizzero. La comunicazione nella chat di supporto viene registrata e analizzata in forma anonima per scopi di analisi e per il miglioramento della qualità del servizio. Per garantire il funzionamento della chat di supporto e del software MIL Office, i dati vengono condivisi anche con il fornitore del software e della chat di supporto. Questo scambio avviene esclusivamente per il miglioramento del servizio e nel rispetto della riservatezza. La condivisione con terzi non appartenenti all'Esercito Svizzero o al fornitore di MIL Office è esclusa.

## 4. Esclusione di responsabilità

La chat di supporto si basa su una tecnologia KI e fornisce informazioni generali. L'operatore della chat non si assume alcuna responsabilità per danni diretti o indiretti derivanti dall'uso della chat. Le risposte fornite sono indicative e devono essere verificate dagli utenti per accuratezza e completezza prima di essere applicate.

## 5. Limitazioni dell'uso

Gli utenti si impegnano a utilizzare la chat di supporto esclusivamente per supportare il proprio lavoro relativo al software MIL Office. È vietato l'uso improprio della chat, ad esempio per scopi personali, contenuti inappropriati o tematiche relative alla sicurezza.

## Imprint e Contatti

Dipartimento federale della difesa, della protezione della popolazione e dello sport (DDPS)   
Comando Istruzione   
Papiermühlestrasse 14  
3003 Berna  
email: triagemiloffice.ausb@vtg.admin.ch
    `,
};
const Terms = () => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    let markdown = '';
    switch (lang) {
        case 'fr':
            markdown = content.fr;
            break;
        case 'it':
            markdown = content.it;
            break;
        default:
            markdown = content.de;
            break;
    }

    return (
        <div className="inline-flex w-full flex-col items-center justify-center gap-12 px-4 py-12">
            <Logo className="size-20" />
            <Markdown remarkPlugins={[remarkGfm]} className="prose prose-sm max-w-screen-md text-dark">
                {markdown}
            </Markdown>
        </div>
    );
};

export default Terms;
