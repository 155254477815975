import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Source } from '../api-client';
import { ReactComponent as Logo } from '../shared/assets/logo.svg';
import { Link } from '../shared/components';
import MessageListItem from './MessageListItem';

export interface Message {
    sender: 'user' | 'bot';
    text: string;
    sources: Source[];
    id?: string;
}

interface MessageListProps {
    messages: Message[];
    isLoading: boolean;
}

const MessageList: React.FC<MessageListProps> = ({ messages, isLoading = false }) => {
    const { t, i18n } = useTranslation();
    const [isRendered, setIsRendered] = useState<Boolean>(false);
    const lastItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        lastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        setIsRendered(true);
    }, []);

    const classNameTransition = `transform transition-all duration-300 delay-300 ease-in-out ${isRendered ? 'translate-none opacity-100' : 'translate-y-4 opacity-0'}`;

    return (
        <div className={'inline-flex flex-col items-start justify-start self-stretch overflow-auto p-4'}>
            <div
                className={`${classNameTransition} inline-flex flex-col items-center justify-start gap-3 self-stretch py-12`}
            >
                <Logo className="size-20" />
                <p className="text-base text-dark">{t('introduction')}</p>
                <span className="text-center text-sm">
                    <Link href={`/terms?lang=${i18n.language}`} showIcon={false}>
                        {t('linkTerms')}
                    </Link>
                </span>
            </div>
            {messages.map((message, index) => (
                <MessageListItem
                    ref={index === messages.length - 1 && !isLoading ? lastItemRef : null}
                    variant={message.sender}
                    text={message.text}
                    sources={message.sources}
                    messageId={message.id}
                    isLoading={false}
                    key={index}
                />
            ))}
            {isLoading && <MessageListItem ref={lastItemRef} variant="bot" isLoading={true} />}
        </div>
    );
};

export default MessageList as React.FC<MessageListProps>;
