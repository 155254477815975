// components/Chat.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Source } from '../api-client';
import { useApiReply } from '../hooks/useApi';
import { Alert, Button, Icon } from '../shared/components';
import MessageList, { Message } from './MessageList';
import Question from './Question';

const Chat: React.FC = () => {
    const { t } = useTranslation();
    const { reply, replyRequestState } = useApiReply();
    const [messages, setMessages] = useState<Message[]>([]);
    const [conversationId, setConversationId] = useState<string | undefined>(undefined);
    const [isRendered, setIsRendered] = useState<Boolean>(false);
    const questionRef = useRef<HTMLTextAreaElement>(null);

    const addMessage = (sender: 'user' | 'bot', text: string, sources: Source[] = [], id?: string): void => {
        const message: Message = {
            sender: sender,
            text: text,
            sources: sources,
            id: id,
        };
        setMessages((messages: Message[]) => [...messages, message]);
    };

    const handleSubmit = async (question: string): Promise<void> => {
        addMessage('user', question);
        const response = await reply(question, conversationId);
        if (response) {
            addMessage('bot', response.message, response.sources, response.messageId);
            setConversationId(response.conversationId);
        }
    };

    const handleNewConversation = (): void => {
        window.location.reload();
    };

    useEffect(() => {
        questionRef.current?.focus();
    });

    useEffect(() => {
        setIsRendered(true);
    }, []);

    const classNameTransition = `transition-all duration-500 delay-0 ease-in-out ${isRendered ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`;

    return (
        <div className="inline-flex size-full flex-col items-center justify-between">
            <MessageList messages={messages} isLoading={replyRequestState === 'loading'} />
            <div className="inline-flex flex-col items-center justify-end self-stretch">
                <div
                    className={`${classNameTransition} mx-auto inline-flex w-full max-w-screen-md flex-col items-start justify-end gap-4 p-4`}
                >
                    {replyRequestState === 'error' && <Alert variant="error">{t('error')}</Alert>}

                    <div
                        className={`${classNameTransition} inline-flex flex-row items-start justify-start gap-2 self-stretch`}
                    >
                        <Button variant="primary" tooltip={t('buttonNewConversation')} onClick={handleNewConversation}>
                            <Icon icon="commentInsert" />
                        </Button>
                        <Question
                            ref={questionRef}
                            readOnly={replyRequestState === 'loading'}
                            onSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
