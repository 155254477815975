/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Rating } from './Rating';
import {
    RatingFromJSON,
    RatingFromJSONTyped,
    RatingToJSON,
} from './Rating';

/**
 * 
 * @export
 * @interface ChatRating
 */
export interface ChatRating {
    /**
     * 
     * @type {string}
     * @memberof ChatRating
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatRating
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatRating
     */
    date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatRating
     */
    hash?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatRating
     */
    messageId: string;
    /**
     * 
     * @type {Rating}
     * @memberof ChatRating
     */
    rating?: Rating | null;
}



/**
 * Check if a given object implements the ChatRating interface.
 */
export function instanceOfChatRating(value: object): value is ChatRating {
    if (!('messageId' in value) || value['messageId'] === undefined) return false;
    return true;
}

export function ChatRatingFromJSON(json: any): ChatRating {
    return ChatRatingFromJSONTyped(json, false);
}

export function ChatRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatRating {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'url': json['url'] == null ? undefined : json['url'],
        'date': json['date'] == null ? undefined : json['date'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'messageId': json['message_id'],
        'rating': json['rating'] == null ? undefined : RatingFromJSON(json['rating']),
    };
}

export function ChatRatingToJSON(value?: ChatRating | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'url': value['url'],
        'date': value['date'],
        'hash': value['hash'],
        'message_id': value['messageId'],
        'rating': RatingToJSON(value['rating']),
    };
}

