import React from 'react';

interface SpinnerProps {
    show: boolean;
    children?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ show, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="inline-flex flex-row items-center justify-start gap-2">
            <div className="size-5 animate-scaleout rounded-full bg-status-success" />
            {children && <span className="text-base text-status-success">{children}</span>}
        </div>
    );
};

export default Spinner;
