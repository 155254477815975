/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChatMessage,
  ChatRating,
  HTTPValidationError,
  LLMResponse,
} from '../models/index';
import {
    ChatMessageFromJSON,
    ChatMessageToJSON,
    ChatRatingFromJSON,
    ChatRatingToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LLMResponseFromJSON,
    LLMResponseToJSON,
} from '../models/index';

export interface RateReplyRequest {
    chatRating: ChatRating;
}

export interface ReplyRequest {
    chatMessage: ChatMessage;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Status
     */
    async getStatusGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Status
     */
    async getStatusGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Rate Reply
     */
    async rateReplyRaw(requestParameters: RateReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['chatRating'] == null) {
            throw new runtime.RequiredError(
                'chatRating',
                'Required parameter "chatRating" was null or undefined when calling rateReply().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rate_reply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatRatingToJSON(requestParameters['chatRating']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Rate Reply
     */
    async rateReply(requestParameters: RateReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.rateReplyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reply
     */
    async replyRaw(requestParameters: ReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LLMResponse>> {
        if (requestParameters['chatMessage'] == null) {
            throw new runtime.RequiredError(
                'chatMessage',
                'Required parameter "chatMessage" was null or undefined when calling reply().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageToJSON(requestParameters['chatMessage']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LLMResponseFromJSON(jsonValue));
    }

    /**
     * Reply
     */
    async reply(requestParameters: ReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LLMResponse> {
        const response = await this.replyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
