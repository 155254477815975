/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    filepath: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    url?: string | null;
}

/**
 * Check if a given object implements the Source interface.
 */
export function instanceOfSource(value: object): value is Source {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('filepath' in value) || value['filepath'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function SourceFromJSON(json: any): Source {
    return SourceFromJSONTyped(json, false);
}

export function SourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Source {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'filepath': json['filepath'],
        'content': json['content'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function SourceToJSON(value?: Source | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'filepath': value['filepath'],
        'content': value['content'],
        'url': value['url'],
    };
}

