import React from 'react';
import { useTranslation } from 'react-i18next';

interface SourceListItemProps {
    number: number;
    title: string;
    active: boolean;
    onActivate?: () => void;
}

const SourceListItem: React.FC<SourceListItemProps> = ({ number, title, active = false, onActivate }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        if (onActivate) {
            onActivate();
        }
    };

    return (
        <li>
            <button
                title={t('buttonSource', { n: number })}
                className={`inline-flex flex-row rounded border border-item-selected ${active ? 'bg-item-selected' : 'hover:bg-item-selected'}`}
                onClick={handleClick}
            >
                <span className="bg-item-selected px-2 py-0.5">{number}</span>
                <span className="truncate px-2 py-0.5">{title}</span>
            </button>
        </li>
    );
};

export default SourceListItem;
