// hooks/useReplyPost.ts
import { useState } from 'react';
import {
    Configuration,
    ConfigurationParameters,
    DefaultApi,
    RateReplyRequest,
    Rating,
    ReplyRequest,
} from '../api-client';
import { CustomWindow } from '../utils/customWindow';

declare const window: CustomWindow;
type RequestState = 'idle' | 'loading' | 'success' | 'error';

const configParams: ConfigurationParameters = {
    basePath: (() => window._env_.MILO_AI_BACKEND_URL)(),
};
const config = new Configuration(configParams);
const apiInstance = new DefaultApi(config);

const getUrlParameters = () => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId') || undefined;
    const urlParam = params.get('url') || undefined;
    const hash = params.get('hash') || undefined;
    const date = params.get('date') || undefined;

    return { userId, urlParam, date, hash };
};

export const useApiReply = () => {
    const [replyRequestState, setRequestState] = useState<RequestState>('idle');

    const reply = async (text: string, conversationId?: string) => {
        setRequestState('loading');

        const { userId, urlParam, date, hash } = getUrlParameters();

        const requestParameters: ReplyRequest = {
            chatMessage: {
                message: text,
                conversationId: conversationId,
                userId: userId,
                url: urlParam,
                date: date,
                hash: hash,
            },
        };

        try {
            const response = await apiInstance.reply(requestParameters);
            setRequestState('success');
            return response;
        } catch (error) {
            setRequestState('error');
            return;
        }
    };

    return { reply, replyRequestState };
};

export const useApiRateReply = () => {
    const [rateReplyRequestState, setRequestState] = useState<RequestState>('idle');

    const rateReply = async (rating: Rating, messageId: string) => {
        setRequestState('loading');

        const { userId, urlParam, date, hash } = getUrlParameters();

        const requestParameters: RateReplyRequest = {
            chatRating: {
                rating: rating,
                messageId: messageId,
                userId: userId,
                url: urlParam,
                date: date,
                hash: hash,
            },
        };

        try {
            await apiInstance.rateReply(requestParameters);
            setRequestState('success');
        } catch (error) {
            setRequestState('error');
        }
    };

    return { rateReply, rateReplyRequestState };
};
