/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * 
 * @export
 * @interface LLMResponse
 */
export interface LLMResponse {
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    message: string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof LLMResponse
     */
    sources: Array<Source>;
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    conversationTopic: string;
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    conversationId: string;
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    messageId: string;
}

/**
 * Check if a given object implements the LLMResponse interface.
 */
export function instanceOfLLMResponse(value: object): value is LLMResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('sources' in value) || value['sources'] === undefined) return false;
    if (!('conversationTopic' in value) || value['conversationTopic'] === undefined) return false;
    if (!('conversationId' in value) || value['conversationId'] === undefined) return false;
    if (!('messageId' in value) || value['messageId'] === undefined) return false;
    return true;
}

export function LLMResponseFromJSON(json: any): LLMResponse {
    return LLMResponseFromJSONTyped(json, false);
}

export function LLMResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LLMResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'sources': ((json['sources'] as Array<any>).map(SourceFromJSON)),
        'conversationTopic': json['conversation_topic'],
        'conversationId': json['conversation_id'],
        'messageId': json['message_id'],
    };
}

export function LLMResponseToJSON(value?: LLMResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'sources': ((value['sources'] as Array<any>).map(SourceToJSON)),
        'conversation_topic': value['conversationTopic'],
        'conversation_id': value['conversationId'],
        'message_id': value['messageId'],
    };
}

