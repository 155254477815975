import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../assets/icons/arrowDownIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/arrowRightIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../assets/icons/checkCircleIcon.svg';
import { ReactComponent as CommentInsertIcon } from '../assets/icons/commentInsertIcon.svg';
import { ReactComponent as CopyIcon } from '../assets/icons/copyIcon.svg';
import { ReactComponent as HyperlinkIcon } from '../assets/icons/hyperlinkOpenSmIcon.svg';
import { ReactComponent as PaperPlaneIcon } from '../assets/icons/paperPlaneIcon.svg';
import { ReactComponent as RadioButtonIcon } from '../assets/icons/radiobuttonIcon.svg';
import { ReactComponent as ThumbDownIconFilled } from '../assets/icons/thumbDownIcon.svg';
import { ReactComponent as ThumbDownIcon } from '../assets/icons/thumbDownOutlineIcon.svg';
import { ReactComponent as ThumbUpIconFilled } from '../assets/icons/thumbUpIcon.svg';
import { ReactComponent as ThumbUpIcon } from '../assets/icons/thumbUpOutlineIcon.svg';
import { ReactComponent as WarningCircleIcon } from '../assets/icons/warningCircleIcon.svg';
import { ReactComponent as XIcon } from '../assets/icons/xIcon.svg';

const IconMap = {
    arrowDown: [ArrowDownIcon, ArrowDownIcon],
    arrowRight: [ArrowRightIcon, ArrowRightIcon],
    checkCircle: [CheckCircleIcon, CheckCircleIcon],
    commentInsert: [CommentInsertIcon, CommentInsertIcon],
    copy: [CopyIcon, CopyIcon],
    hyperlink: [HyperlinkIcon, HyperlinkIcon],
    paperPlane: [PaperPlaneIcon, PaperPlaneIcon],
    radioButton: [RadioButtonIcon, RadioButtonIcon],
    thumbDown: [ThumbDownIcon, ThumbDownIconFilled],
    thumbUp: [ThumbUpIcon, ThumbUpIconFilled],
    warningCircle: [WarningCircleIcon, WarningCircleIcon],
    x: [XIcon, XIcon],
};

interface IconProps {
    icon:
        | 'arrowDown'
        | 'arrowRight'
        | 'checkCircle'
        | 'commentInsert'
        | 'copy'
        | 'hyperlink'
        | 'paperPlane'
        | 'radioButton'
        | 'thumbDown'
        | 'thumbUp'
        | 'warningCircle'
        | 'x';
    fill?: boolean;
    animateFill?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export const Icon: React.FC<IconProps> = ({ icon, fill = false, animateFill = false, size = 'small' }) => {
    const IconComponent = IconMap[icon][fill ? 1 : 0];
    const [fillTransitioned, setFillTransitioned] = useState<Boolean>(false);
    const classNameTransition = `transform transition-all duration-500 ease-in-out delay-300' ${!fill ? 'scale-100 opacity-100' : fillTransitioned ? 'scale-125 opacity-100' : 'scale-75 opacity-100'}`;
    const classNameSize = size === 'small' ? 'size-4' : size === 'medium' ? 'size-6' : 'size-8';

    useEffect(() => {
        if (fill) {
            setFillTransitioned(false);
            setTimeout(() => {
                setFillTransitioned(true);
            }, 0);
        } else {
            setFillTransitioned(false);
        }
    }, [fill]);

    return <IconComponent className={`${animateFill ? classNameTransition : ''} ${classNameSize}`} />;
};

export default Icon as React.FC<IconProps>;
